<template>
  <article class="game_frame border_box">
    <!-- <eospowerball5 /> -->
    <iframe
      src="https://ballpick.co.kr/live/eospowerball5"
      width="840"
      height="620"
      scrolling="no"
      frameborder="0"
      allow="autoplay"
    />
    <iframe
      src="https://acbpdev.work/live/eospowerball5"
      width="840"
      height="620"
      scrolling="no"
      frameborder="0"
      allow="autoplay"
    />
  </article>
  <live
    :gameType="gameType"
    :diffTime="diffTime"
    :nextGameInfo="nextGameInfo"
  />
</template>
<script>
import live from "@/components/game/pc/powerball/live.vue";
import eospowerball5 from "@/views/pc/gameFrame/eospowerball5.vue";
export default {
  name: "eospowerball5Live",
  components: {
    eospowerball5,
    live,
  },
  computed: {
    diffTime() {
      return this.$store.getters.EOSpowerball5diffTime;
    },
    nextGameInfo() {
      return this.$store.getters.nextEOSpowerball5Info;
    },
    gameType() {
      return "eospowerball5";
    },
  },
};
</script>
